@import '/src/styles/utilities.scss';

.headerFeature {
  background: var(--background-color);
}

.headerFeature__backgroundImage {
  @include media-breakpoint-up(lg) {
    display: block;
    width: 100%;
    max-width: $breakpoint-xl;
    height: 100%;
    min-height: $height-feature-header-desktop;
    margin: 0 auto;
    background-image: var(--background-image);
    background-repeat: no-repeat;
    background-position: right center;
    background-size: contain;
  }
}

.headerFeature__content {
  padding: $space-32 0 $space-16;

  @include media-breakpoint-up(lg) {
    margin: 0 auto;
  }
}

.headerFeature__copy {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @include media-breakpoint-up(lg) {
    align-items: flex-start;
    max-width: 60%;
    text-align: left;
  }
}

.headerFeature__nav {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: $space-16;
  align-items: center;
  width: 100%;
  margin-top: $space-16;

  @include media-breakpoint-up(lg) {
    width: auto;
    margin-top: $space-8;
  }
}

.headerFeature__badge {
  max-width: var(--badge-size-phone);
  margin-bottom: $space-16;

  @include media-breakpoint-only(md) {
    max-width: var(--badge-size-tablet-desktop);
  }

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.headerFeature__title {
  width: 100%;
  margin-bottom: 0;
  color: var(--color-text-dark);

  &_long {
    margin-bottom: $space-8;
  }
}

.headerFeature__subtitle {
  width: 100%;
  margin-bottom: $space-16;
  color: var(--color-gray-700);

  @include media-breakpoint-up(lg) {
    color: var(--color-text-dark);
  }
}

.headerFeature__addFollow {
  button {
    min-width: 140px;
  }
}
